@import "../../styles/mixins/flex";

.Overview-Information {
  justify-content: space-between;
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .Overview-Information {
    @include flex-column;
  }
  .Overview-Information p:last-child {
    margin-top: 20px;
    font-size: 20px !important;
  }
}

.charts-label {
  font-size: 1rem;
  font-weight: 600;

  @media only screen and (max-width: 992px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 15px;
  }
}
.sector-page {
  position: relative;
  margin-top: 30px;
}

.hero-img-container {
  position: relative;
  overflow: hidden;
}

.hero-img-sector {
  width: 100%;
  height: 100vh;
  display: block;
}
