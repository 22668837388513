$color-white: #fff;
$color-black: #000;
$color-gray: #636363;
$color-light-gray: #c4c4c4;
$color-red: #e85c7a;
$color-pure-red: red;
$color-green: #0ec224;
$color-purple: #6455c2;
$color-dark-purple: #36166e;
$color-light-green: #d3f6e0;
