.thank-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.smile-icon{
    height: 50px;
    width: 50px;
    margin: 0 20px;
    margin-top: -14px;
}