@import "../../styles/mixins/_dimensions.scss";
#scroll-container {
}
.container-nav {
  @include container-nav;
  padding-top: 60px !important;
}
.mobile-icon-menu {
  display: none;
}
li {
  cursor: pointer;
}

.first-list {
  width: 15%;
}
.logo {
  font-family: "Averta CY" !important;
  font-size: 22px;
}

.light-mode {
  .logo {
    color: var(--Colour-2);
    font-weight: 600;

    display: flex;
    align-items: center;
    margin-left: -30px;
    z-index: 11;
    cursor: pointer;
  }
}

.dark-mode {
  .logo {
    color: var(--Colour-1);
    font-weight: 600;

    display: flex;
    align-items: center;
    margin-left: -30px;
    z-index: 11;
    cursor: pointer;
  }
}

.icons {
  width: auto;
  cursor: pointer;
  justify-content: flex-start;
  position: relative;
  width: 220px;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
  font-size: 18px;
  text-transform: capitalize;
  margin-right: 40px;
  > div {
    min-width: 60px;
    > a {
      width: 100%;
      font-weight: 300 !important;
      * {
        font-weight: 300 !important;
      }
    }
    > div.user-icon {
      font-weight: 300 !important;
      * {
        font-weight: 300 !important;
      }
    }
  }

  .spanSvg {
    display: block;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(0.75);
  }

  > div {
    > div.icon.user-icon {
      //  display: none;
    }
  }
  .search-icon {
    .spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.5026 24.5002L19.2689 19.2572L24.5026 24.5002ZM22.1693 12.2502C22.1693 14.8802 21.1245 17.4026 19.2647 19.2623C17.405 21.122 14.8827 22.1668 12.2526 22.1668C9.62254 22.1668 7.1002 21.122 5.24046 19.2623C3.38073 17.4026 2.33594 14.8802 2.33594 12.2502C2.33594 9.6201 3.38073 7.09776 5.24046 5.23802C7.1002 3.37828 9.62254 2.3335 12.2526 2.3335C14.8827 2.3335 17.405 3.37828 19.2647 5.23802C21.1245 7.09776 22.1693 9.6201 22.1693 12.2502V12.2502Z' stroke='%23000' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
    .magnetic-area:hover + #myspan span.spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.5026 24.5002L19.2689 19.2572L24.5026 24.5002ZM22.1693 12.2502C22.1693 14.8802 21.1245 17.4026 19.2647 19.2623C17.405 21.122 14.8827 22.1668 12.2526 22.1668C9.62254 22.1668 7.1002 21.122 5.24046 19.2623C3.38073 17.4026 2.33594 14.8802 2.33594 12.2502C2.33594 9.6201 3.38073 7.09776 5.24046 5.23802C7.1002 3.37828 9.62254 2.3335 12.2526 2.3335C14.8827 2.3335 17.405 3.37828 19.2647 5.23802C21.1245 7.09776 22.1693 9.6201 22.1693 12.2502V12.2502Z' stroke='%23fff' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
  .user-icon {
    .spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.7559 17.7625C19.2401 16.5407 18.4916 15.4309 17.552 14.4949C16.6153 13.5562 15.5057 12.8078 14.2844 12.291C14.2735 12.2855 14.2626 12.2828 14.2516 12.2773C15.9551 11.0469 17.0626 9.04258 17.0626 6.78125C17.0626 3.03516 14.0274 0 10.2813 0C6.53523 0 3.50007 3.03516 3.50007 6.78125C3.50007 9.04258 4.60749 11.0469 6.31101 12.2801C6.30007 12.2855 6.28913 12.2883 6.27819 12.2937C5.05319 12.8105 3.95398 13.5516 3.01062 14.4977C2.07193 15.4344 1.32352 16.544 0.806709 17.7652C0.298996 18.9608 0.0251752 20.2427 6.83758e-05 21.5414C-0.000661443 21.5706 0.00445738 21.5996 0.0151234 21.6268C0.0257894 21.654 0.0417867 21.6787 0.0621726 21.6996C0.0825586 21.7205 0.106921 21.7371 0.133824 21.7485C0.160726 21.7598 0.189626 21.7656 0.218818 21.7656H1.85944C1.97976 21.7656 2.07546 21.6699 2.07819 21.5523C2.13288 19.4414 2.98054 17.4645 4.47898 15.966C6.02937 14.4156 8.08835 13.5625 10.2813 13.5625C12.4743 13.5625 14.5333 14.4156 16.0837 15.966C17.5821 17.4645 18.4298 19.4414 18.4844 21.5523C18.4872 21.6727 18.5829 21.7656 18.7032 21.7656H20.3438C20.373 21.7656 20.4019 21.7598 20.4288 21.7485C20.4557 21.7371 20.4801 21.7205 20.5005 21.6996C20.5209 21.6787 20.5369 21.654 20.5475 21.6268C20.5582 21.5996 20.5633 21.5706 20.5626 21.5414C20.5352 20.2344 20.2645 18.9629 19.7559 17.7625ZM10.2813 11.4844C9.02624 11.4844 7.84499 10.9949 6.95632 10.1062C6.06765 9.21758 5.57819 8.03633 5.57819 6.78125C5.57819 5.52617 6.06765 4.34492 6.95632 3.45625C7.84499 2.56758 9.02624 2.07812 10.2813 2.07812C11.5364 2.07812 12.7176 2.56758 13.6063 3.45625C14.495 4.34492 14.9844 5.52617 14.9844 6.78125C14.9844 8.03633 14.495 9.21758 13.6063 10.1062C12.7176 10.9949 11.5364 11.4844 10.2813 11.4844Z' fill='black'/%3E%3C/svg%3E%0A");
    }
    .magnetic-area:hover + #myspan span.spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.7559 17.7625C19.2401 16.5407 18.4916 15.4309 17.552 14.4949C16.6153 13.5562 15.5057 12.8078 14.2844 12.291C14.2735 12.2855 14.2626 12.2828 14.2516 12.2773C15.9551 11.0469 17.0626 9.04258 17.0626 6.78125C17.0626 3.03516 14.0274 0 10.2813 0C6.53523 0 3.50007 3.03516 3.50007 6.78125C3.50007 9.04258 4.60749 11.0469 6.31101 12.2801C6.30007 12.2855 6.28913 12.2883 6.27819 12.2937C5.05319 12.8105 3.95398 13.5516 3.01062 14.4977C2.07193 15.4344 1.32352 16.544 0.806709 17.7652C0.298996 18.9608 0.0251752 20.2427 6.83758e-05 21.5414C-0.000661443 21.5706 0.00445738 21.5996 0.0151234 21.6268C0.0257894 21.654 0.0417867 21.6787 0.0621726 21.6996C0.0825586 21.7205 0.106921 21.7371 0.133824 21.7485C0.160726 21.7598 0.189626 21.7656 0.218818 21.7656H1.85944C1.97976 21.7656 2.07546 21.6699 2.07819 21.5523C2.13288 19.4414 2.98054 17.4645 4.47898 15.966C6.02937 14.4156 8.08835 13.5625 10.2813 13.5625C12.4743 13.5625 14.5333 14.4156 16.0837 15.966C17.5821 17.4645 18.4298 19.4414 18.4844 21.5523C18.4872 21.6727 18.5829 21.7656 18.7032 21.7656H20.3438C20.373 21.7656 20.4019 21.7598 20.4288 21.7485C20.4557 21.7371 20.4801 21.7205 20.5005 21.6996C20.5209 21.6787 20.5369 21.654 20.5475 21.6268C20.5582 21.5996 20.5633 21.5706 20.5626 21.5414C20.5352 20.2344 20.2645 18.9629 19.7559 17.7625ZM10.2813 11.4844C9.02624 11.4844 7.84499 10.9949 6.95632 10.1062C6.06765 9.21758 5.57819 8.03633 5.57819 6.78125C5.57819 5.52617 6.06765 4.34492 6.95632 3.45625C7.84499 2.56758 9.02624 2.07812 10.2813 2.07812C11.5364 2.07812 12.7176 2.56758 13.6063 3.45625C14.495 4.34492 14.9844 5.52617 14.9844 6.78125C14.9844 8.03633 14.495 9.21758 13.6063 10.1062C12.7176 10.9949 11.5364 11.4844 10.2813 11.4844Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    }
  }
}

.icon {
  // margin-right: 20px;
}
.dark-mode .icons {
  color: var(--Colour-1);
}

.Social-item a {
  color: var(--Colour-2);
}

.dark-mode .Social-item a {
  color: var(--Colour-1);
}

.toggle-menu {
  display: block;
  position: relative;
  margin: 2rem;
  width: 3rem;
  height: 3rem;
  text-indent: -999em;
  cursor: pointer;
}

.toggle-menu--clicked {
  .menu__bar:nth-child(1) {
    top: 1.25rem;
    transform: rotate(45deg);
  }
}

.toggle-menu--clicked {
  .menu__bar :nth-child(2) {
    transform: translateX(100%) scale(0);
  }
}
.toggle-menu--clicked {
  .menu__bar :nth-child(3) {
    top: 1.25rem;
    transform: rotate(-45deg);
  }
}
.menu__bar {
  display: block;
  position: absolute;
  left: 0;
  width: 3rem;
  height: 0.5rem;
  transition: top 0.4s ease-out, transform 0.4s ease-out;
  background-color: #222;
}

.menu__bar:nth-child(1) {
  top: 0;
}

.menu__bar:nth-child(2) {
  top: 1.25rem;
}

.menu__bar:nth-child(3) {
  top: 2.5rem;
}

.dark-mode .menuClose:hover > label > div {
  /* background-color: green !important; */
}

.dark-mode .menuOpen:hover > label > div {
  background-color: transparent !important;
}

.light-mode .menuClose:hover > label > div {
  /* background-color: white !important; */
}

.light-mode .menuOpen:hover > label > div,
.light-mode .menuOpen > label > div {
  background-color: transparent !important;
}

.nav-link-wrapper {
  cursor: pointer;
}

.nav-link-wrapper:hover .nav-link-hover-wrapper {
  transform: translateY(-105%) !important;
}

.nav-link-wrapper:hover .nav-link-hover::before {
  transform: skewY(7deg);
}

.nav-link-wrapper:hover .nav-link-hover::after {
  transform: skewY(0);
}

.nav-link {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.nav-link-hover-wrapper {
  display: inline-block;
  font-style: normal;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.nav-link-hover {
  display: inline-block;
  color: transparent;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  font-family: "Averta CY" !important;
}

.nav-link-hover::before,
.nav-link-hover::after {
  content: attr(data-text);
  display: block;
  position: absolute;
  transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
}

.light-mode .nav-link-hover::before,
.light-mode .nav-link-hover::after {
  color: black;
}

.dark-mode .nav-link-hover::before,
.dark-mode .nav-link-hover::after {
  color: white;
}

.nav-link-hover::before {
  top: 0;
  transition: skewY(0);
  transform-origin: right bottom;
}

.nav-link-hover::after {
  top: 105%;
  transform: skewY(7deg);
  transform-origin: left top;
}

.list-nav {
  gap: 10rem;
}

.list-nav {
  .Social-item {
    font-size: 1rem;
    margin-top: 0.8rem;
  }
}

.list-nav {
  .menu-item {
    display: inline-block;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-top: 0.3rem;
  }
}

.second-list {
  /* margin-left: 100px; */
  a {
    font-weight: 400;
    font-family: "Averta CY";
  }
}
.first-list {
  a {
    font-family: "Averta CY", sans-serif;
    font-size: 16px !important;
  }
}
.list-nav {
  .menu-item {
    @media (min-width: 360px) {
      font-size: 42px;
    }
    @media (min-width: 768px) and (min-height: 550px) {
      font-size: 55px;
    }
    @media (min-width: 1600px) and (min-height: 700px) {
      font-size: 55px;
    }
  }
  .second-list {
    > li {
      &:nth-of-type(3) {
        // border-bottom: 1px solid #000;
      }
    }
  }
}

.menu-color {
  font-family: "Averta CY", sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.3);
  cursor: text;
  margin-bottom: 45px !important;
}
.dark-mode {
  .menu-color {
    color: #fff;
  }
}

.contact-menu {
  font-size: 1rem;
  margin-top: 2rem;
  justify-self: flex-end;
  .line-animation-1 {
    text-transform: lowercase;
  }
  @media only screen and (max-width: 768px) {
    position: absolute;
    bottom: 50px;
  }
}

/* HamburgerBar related styles start */
.hamburger {
  /* width: 5rem;
  height: 5rem; */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 300ms ease;
  transition: 0.5s;
}
.hamburger:hover {
  /* background-color: var(--barBgColor); */
}
.light-mode .hamburger {
  --barBgColor: black;
  --barBgColorHover: white;
}
.dark-mode .hamburger {
  --barBgColor: white;
  --barBgColorHover: black;
}

.hamburger__bar-wrapper {
  // --barGap: 0.25rem;
  // display: flex;
  // flex-direction: column;
  //gap: var(--barGap);
  width: 100%;

  /*css ad cuberto */

  position: relative;
  top: -1px;
  display: inline-block;
  width: 25px;
  line-height: 25px;
  vertical-align: middle;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    right: -20px;
    left: -20px;
    bottom: -20px;
  }
}
.hamburger__bar {
  /*
  --barHeight: 2px;
  --transformOrigin: center center;
  --menuOpenAnimationDuration: 750ms;
  --menuOpenAnimationTimingFunc: ease;
  --menuCloseAnimationDuration: 300ms;
  --menuCloseAnimationDelay: 300ms;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--barHeight);
  background-color: var(--barBgColor);
  transform-origin: var(--transformOrigin);
  transform: rotate(0) scaleX(1);
  transition: top var(--menuCloseAnimationDuration) var(--menuCloseAnimationDelay), transform var(--menuCloseAnimationDuration);
  */
  width: 25px !important;
  height: 2px;
}
.contact-menu {
  .menu-color {
    margin-bottom: 5px !important;
  }
}
.hamburger__bar.top {
  /*
  --menuOpenTopVal: -0.4rem;
  --menuOpenLeftVal: 0.35rem;
  --menuOpenRotateVal: 45deg;
  --menuCloseTopVal: calc(calc(var(--barGap) / 2) + calc(var(--barHeight) / 2));
  --menuCloseLeftVal: 0;
  --menuCloseRotateVal: 45deg;
  */
  /* default css cuberto */
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  margin: 5px 0;
  top: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background: currentColor;
  -webkit-transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  -o-transition: top 0.3s 0.3s, -o-transform 0.3s;
  -moz-transition: top 0.3s 0.3s, transform 0.3s, -moz-transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s, -webkit-transform 0.3s,
    -moz-transform 0.3s, -o-transform 0.3s;
}
.hamburger__bar.bottom {
  /*
  --menuOpenTopVal: calc(-0.4rem - var(--barGap) - var(--barHeight));
  --menuOpenLeftVal: -0.35rem;
  --menuOpenRotateVal: -45deg;
  --menuCloseTopVal: calc(calc(var(--barGap) / -2) - calc(var(--barHeight) / 2));
  --menuCloseLeftVal: 0;
  --menuCloseRotateVal: -45deg;
  */
  /*Default css as cuberto */

  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  margin: 5px 0;
  top: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background: currentColor;
  -webkit-transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  -o-transition: top 0.3s 0.3s, -o-transform 0.3s;
  -moz-transition: top 0.3s 0.3s, transform 0.3s, -moz-transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s, -webkit-transform 0.3s,
    -moz-transform 0.3s, -o-transform 0.3s;
}
.magnetic-area:hover + #stickyElem .hamburger__bar {
  background-color: var(--barBgColorHover);
}
.hamburger.open .hamburger__bar.middle {
  opacity: 0;
}

.hamburger.open .hamburger__bar.top {
  //top: var(--menuCloseTopVal);
  //left: var(--menuCloseLeftVal);
  //transform: rotate(var(--menuCloseRotateVal));
  //animation: menuOpenTopBar var(--menuOpenAnimationDuration) var(--menuOpenAnimationTimingFunc);

  /*
  -webkit-transform:rotate(45deg) translate(5px, 5px);
  -moz-transform: rotate(45deg) translate(5px, 5px);
  -ms-transform: rotate(45deg) translate(5px, 5px);
  -o-transform:rotate(45deg) translate(5px, 5px);
  transform:rotate(45deg) translate(5px, 5px);
  */
  /*default css as cuberto */

  /*css as cuberto */
  -webkit-animation: cb-btn_menu-open-top 0.75s;
  -moz-animation: cb-btn_menu-open-top 0.75s;
  -o-animation: cb-btn_menu-open-top 0.75s;
  animation: cb-btn_menu-open-top 0.75s;
  top: 4px;
  -webkit-transform: rotate(45deg) scaleX(1);
  -moz-transform: rotate(45deg) scaleX(1);
  -ms-transform: rotate(45deg) scaleX(1);
  -o-transform: rotate(45deg) scaleX(1);
  transform: rotate(45deg) scaleX(1);
  -webkit-transition-duration: 0s, 0s;
  -moz-transition-duration: 0s, 0s;
  -o-transition-duration: 0s, 0s;
  transition-duration: 0s, 0s;
}
.hamburger.open .hamburger__bar.bottom {
  //top: var(--menuCloseTopVal);
  //left: var(--menuCloseLeftVal);
  //transform: rotate(var(--menuCloseRotateVal));
  //animation: menuOpenBottomBar var(--menuOpenAnimationDuration) var(--menuOpenAnimationTimingFunc);

  /*

  -webkit-transform:rotate(-45deg) translate(4px, -3px);
  -moz-transform: rotate(-45deg) translate(4px, -3px);
  -ms-transform: rotate(-45deg) translate(4px, -3px);
  -o-transform:rotate(-45deg) translate(4px, -3px);
  transform:rotate(-45deg) translate(4px, -3px);
  */

  -webkit-animation: cb-btn_menu-open-bottom 0.75s;
  -moz-animation: cb-btn_menu-open-bottom 0.75s;
  -o-animation: cb-btn_menu-open-bottom 0.75s;
  animation: cb-btn_menu-open-bottom 0.75s;
  top: -3px;
  -webkit-transform: rotate(-45deg) scaleX(1);
  -moz-transform: rotate(-45deg) scaleX(1);
  -ms-transform: rotate(-45deg) scaleX(1);
  -o-transform: rotate(-45deg) scaleX(1);
  transform: rotate(-45deg) scaleX(1);
  -webkit-transition-duration: 0s, 0s;
  -moz-transition-duration: 0s, 0s;
  -o-transition-duration: 0s, 0s;
  transition-duration: 0s, 0s;
}

@keyframes menuOpenTopBar {
  0% {
    --transformOrigin: left center;
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(1);
  }
  30% {
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(0);
  }
  30.1% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 0;
    transform: rotate(0) scaleX(0);
  }
  31% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(0);
  }
  100%,
  80% {
    --transformOrigin: left center;
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(1);
  }
}
@keyframes menuOpenBottomBar {
  0% {
    --transformOrigin: right center;
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(1);
  }
  50% {
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(0);
  }
  50.1% {
    top: 0;
    left: 0;
    opacity: 0;
    transform: rotate(0) scaleX(0);
  }
  51%,
  55% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(0);
  }
  100% {
    --transformOrigin: right center;
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(1);
  }
}
/* HamburgerBar related styles end */

@keyframes nav-open {
  from {
    visibility: hidden;
    opacity: 0;
    transform: translateX(var(--startTranslateX));
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(var(--endTranslateX));
  }
}
@keyframes nav-close {
  from {
    opacity: 1;
    visibility: visible;
    transform: translateX(var(--startTranslateX));
  }
  to {
    visibility: hidden;
    opacity: 0;
    transform: translateX(var(--endTranslateX));
  }
}

/* Max width media queries */
/* Note - Put small width media queries first (applicable for only 'min-width' media queries) */
@media screen and (min-width: 1024px) {
  .list-nav {
    .menu-item {
      margin-top: 0;
    }
  }
  .list-nav {
    .Social-item {
      font-size: 0.9rem;
      margin-top: 1.2rem;
    }
  }
}
@media screen and (min-width: 1400px) {
  .list-nav {
    gap: 10rem;
  }
  .list-nav {
    .Social-item {
      margin-top: 0.8rem;

      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}

/* Max width media queries */
/* Note - Put large width media queries first (applicable for only 'max-width' media queries) */
@media screen and (max-width: 992px) {
  .user-icon {
    // margin-right: 50px;
    // margin-left: 6px;
  }
  .icons {
    // width: 290px;
  }
  .dark-mode .logo,
  .light-mode .logo {
    margin-left: 0;
  }

  .list-nav .menu-item {
    font-size: 2.8rem;
  }
  .list-nav {
    .Social-item {
      font-size: 0.85rem;
      margin-top: 0.8rem;
    }
  }

  .contact-menu {
    font-size: 0.9rem;
    margin-top: 0;
  }
}

@media screen and (max-width: 700px) {
  .light-mode .logo {
    font-size: 19px;
  }

  .dark-mode .logo {
    font-size: 19px;
  }

  .icons {
    width: auto;
    align-items: flex-start;
    margin-right: 25px !important;
    position: relative;
    top: 5px;
    > div {
      min-width: 40px;
    }
    .search-icon,
    .user-icon {
      .nav-link-hover {
        display: none;
      }
    }

    .mobile-icon-menu {
      display: block;
      .nav-link-hover {
        display: block;
      }
    }
  }

  .first-list {
    //  display: none;
  }

  .user-icon,
  .search-icon {
    // display: none;
    font-size: 16px;
  }

  .first-list {
    //display: none !important;
  }

  .list-nav {
    .menu-item {
      font-size: 2.2rem;
      line-height: 1.2;
    }
  }

  /* HamburgerBar */
  .hamburger__bar.top {
    --menuOpenTopVal: -0.35rem;
    --menuOpenLeftVal: 0.25rem;
  }
  .hamburger__bar.bottom {
    --menuOpenTopVal: calc(-0.35rem - var(--barGap) - var(--barHeight));
    --menuOpenLeftVal: -0.25rem;
  }
}

@media screen and (max-width: 500px) {
  .contact-menu {
    font-size: 16px;
    margin-top: 1.2rem;
  }
  .list-nav {
    justify-content: start;
  }
}

/*Animation Cuberto */
@-webkit-keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@-moz-keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -moz-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -moz-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -moz-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -moz-transform-origin: left center;
    transform-origin: left center;
  }
}

@-o-keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -o-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -o-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(1);
    -moz-transform: rotate(0) scaleX(1);
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(0);
    -moz-transform: rotate(45deg) scaleX(0);
    -o-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(1);
    -moz-transform: rotate(45deg) scaleX(1);
    -o-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}

@-moz-keyframes cb-btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -moz-transform-origin: right center;
    transform-origin: right center;
  }

  50% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -moz-transform: rotate(-45deg) scaleX(0);
    transform: rotate(-45deg) scaleX(0);
  }

  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -moz-transform: rotate(-45deg) scaleX(1);
    transform: rotate(-45deg) scaleX(1);
    -moz-transform-origin: right center;
    transform-origin: right center;
  }
}

@-o-keyframes cb-btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -o-transform-origin: right center;
    transform-origin: right center;
  }

  50% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -o-transform: rotate(-45deg) scaleX(0);
    transform: rotate(-45deg) scaleX(0);
  }

  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -o-transform: rotate(-45deg) scaleX(1);
    transform: rotate(-45deg) scaleX(1);
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes cb-btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(1);
    -moz-transform: rotate(0) scaleX(1);
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }

  50% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -webkit-transform: rotate(-45deg) scaleX(0);
    -moz-transform: rotate(-45deg) scaleX(0);
    -o-transform: rotate(-45deg) scaleX(0);
    transform: rotate(-45deg) scaleX(0);
  }

  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -webkit-transform: rotate(-45deg) scaleX(1);
    -moz-transform: rotate(-45deg) scaleX(1);
    -o-transform: rotate(-45deg) scaleX(1);
    transform: rotate(-45deg) scaleX(1);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}

#navBackdropSticky {
  background-color: rgba(238, 238, 238, 0.7);
}

#menuLabelWrapper {
  @media screen and (max-width: 1440px) {
    right: 3% !important;
  }
}

#navSticky {
  padding: 0 !important;

  display: flex;
  height: 100%;
  justify-content: center !important;
  > div.vertical-center {
    flex: none !important;

    display: flex;
    height: 70%;
    /*
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    */
  }
  @media (min-width: 768px) {
    width: 689px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 25px !important;
  }

  @media (min-width: 1600px) and (min-height: 700px) {
    width: 880px !important;
    padding: 50px 0 0 0 !important;
  }

  @media (min-width: 768px) {
    .list-nav,
    .contact-menu {
      padding: 0 98px;
    }
  }
  @media (min-width: 1600px) and (min-height: 700px) {
    .contact-menu {
      padding: 0 0 84px 98px;
    }
  }
  .list-nav {
    @media (max-width: 768px) {
      // gap: 5rem;
      justify-content: center;
      width: 100%;
    }
    @media (max-width: 450px) {
      gap: 4rem;
    }
  }
  .contact-menu {
    @media (max-width: 768px) {
      width: 100%;
      width: 60%;
      margin: auto;
      display: block;
      position: static;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

//Edits as other repo
#navSticky .first-list .nav-link-hover {
  font-weight: 300 !important;
}
@media (max-width: 768px) {
  #navSticky .contact-menu {
    display: block;
    margin: 0 auto;
    position: static;
    width: 100%;
    width: 90%;
    margin-top: 55px;
  }
  .menu-color {
    margin-bottom: 25px !important;
  }
  #navSticky > div.vertical-center {
    padding-left: 0px;
  }
  #navSticky .list-nav {
    justify-content: flex-start;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  #navSticky > div.vertical-center {
    height: auto;
  }
  #navSticky .list-nav .menu-item {
    font-size: 40px !important;
    line-height: 46px;
  }
  #navSticky .list-nav {
    justify-content: flex-start;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  #navSticky .first-list {
    display: none;
  }
  #navSticky .list-nav .second-list em {
    font-size: 42px !important;
    line-height: 49px;
  }
  #navSticky .second-list a {
    line-height: 49px;
  }
}

@media (max-width: 376px) {
  #navSticky .list-nav .second-list em {
    font-size: 29px !important;
    line-height: 40px;
  }
  #navSticky .second-list a {
    line-height: 40px !important;
  }
}
