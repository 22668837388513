.special-list-container {
  padding-top: 100px;
}

.special-list-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.special-list-card-item {
  width: calc(33.33% - 1rem); /* Adjust the width as needed */
}
