.horizontal-translate {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.horizontal-translate::before,
.horizontal-translate::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}
.horizontal-translate::before {
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
.horizontal-translate::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.horizontal-translate:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.horizontal-translate:hover::after {
  transform: translate3d(0, 0, 0);
}

.horizontal-translate span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.horizontal-translate:hover span {
  transform: translate3d(-200%, 0, 0);
}
