.w-50 {
  width: 44%;
}
.w-50 img {
  width: 98%;
  height: 200px;
}

.blog-title {
  margin: 70px 0;
}
.highlighted-text {
  background: rgba(171, 171, 171, 0.1);
  border-radius: 50px;
  padding: 50px 40px 50px 40px;
}

.highlighted-text h4 {
  margin-bottom: 50px;
}

.w-20 {
  width: 20%;
}
.success-test {
  background-color: #30e3ca;
  color: #40514e;
  line-height: 1.5;
  margin: 50px 0;
  padding: 10px;
}
.list-items {
  margin: 0 50px;
}
.w-40 {
  width: 49%;
}

@media only screen and (max-width: 900px) {
  .w-40 {
    width: 100%;
  }
  .images-container {
    flex-wrap: wrap;
  }
}

.w-70 {
  width: 70%;
}
.darkblue-test {
  background-color: #311d3f;
  color: #f8f3d4;
  line-height: 1.2;
  margin: 50px 0;
  padding: 10px;
}
.danger-test {
  background-color: #a3003a;
  color: #ffffff;
  line-height: 1.2;
  margin: 50px 0;
  padding: 10px;
}

img {
  object-fit: cover;
}
.mt-2 {
  margin-top: 2em;
}
.hr {
  background-color: #cccccc;
}
.success-alert {
  background-color: #d6efee;
  border-radius: 4px;
  color: #094264;
  padding: 2em;
}
.block-quote {
  border-left: 3px solid #897248;
  margin: 32px 0;
  padding-left: 16px;
  width: 90%;
  margin: auto;
}
.block-quote p {
  font-size: 2rem;
  letter-spacing: normal;
  line-height: 1.125;
}

.main-text {
  color: #ffffff;
  background-color: #ec9544;
  width: 90%;
  margin: 40px auto;
  text-align: center;
  padding: 50px 0;
}

.table-wrapper th {
  width: 50%;
}
.table-wrapper td:last-child {
  text-align: center;
}
.black-text {
  font-size: 0.83333rem;
  border-radius: 5px;
  // box-shadow: 0 4px 6px rgb(0 0 0 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.11), 0 1px 3px rgba(0, 0, 0, 0.8);
  background-color: #000;
  color: #fff;
  padding: 16px;
}
a:hover {
  color: #685636;
}

.red-btn-text {
  background: linear-gradient(200deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 2%, rgb(107, 0, 62) 100%);
  padding: 16px;
  text-align: center;
  color: #fff;
}
