@import '../../styles/mixins/dimensions';
.user-card {
  @include w-45;
}
.w-50 {
  @include w-50;
}

.grid-container {
  display: grid;
  grid-template-columns: 1.75fr 1.25fr;
  padding: 10px;
}

/* .btn-wrapper {
width: 80%;
margin: 0 auto;
margin-top: 100px;
} */
