.table {
  width: 100%;
  border-spacing: 0px;
}
.table tr {
  text-align: left;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px 5px;
}

.first-cell {
  border-bottom: 3px solid #000 !important;
}
.total-cell {
  border-top: 3px solid #000 !important;
}

.warning-alert {
  background-color: rgba(255, 0, 77, 0.1);
  color: #000;
  width: 90%;
  margin: 20px auto;
  padding: 2em;
  border-radius: 4px;
}

.success-alert {
  width: 90%;
  margin: 20px auto;

  background-color: #d6efee;
  border-radius: 4px;
  color: #094264;
  padding: 2em;
}

.video-wrapper {
  height: 500px;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}

.video {
  height: 500px;
  width: 100%;
  margin: 0 auto;
}
