.timeline__container {
  display: flex;
  border-bottom: 1px solid #3b4754;
}

.timeline__buttons__container {
  display: flex;
  align-items: center;
}

.timeline__button {
  font-weight: bold;
  font-size: 35px !important;
  padding: 30px 30px 20px 30px;
  cursor: pointer;
}

.timeline__button:hover {
  color: #5ac53b;
}

.timeline__button.active {
  color: #5ac53b;
  border-bottom: 2px solid #5ac53b;
}
.custom-tab-title {
  font-size: 25px; // Adjust the font size as needed
}
