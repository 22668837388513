@import '../../styles/mixins/spacing';

.filter-wrapper {
  @include m-auto;
}

.filter-item {
  font-family: AvertaSemi;
  font-weight: 700;
}
