input[type="checkbox"].terms-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  position: relative;
  width: 70px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

input[type="checkbox"].terms-checkbox-grey {
  border: 2px solid rgba(0, 0, 0, 0.3);
}
input[type="checkbox"].terms-checkbox-error {
  border: 2px solid red;
}

input[type="checkbox"].terms-checkbox:hover:not(:checked) {
  border-color: #000;
}

input[type="checkbox"].terms-checkbox:checked {
  border-color: #000;
  background-color: #000;
}

.dark-mode input[type="checkbox"].terms-checkbox:checked {
  border-color: #fff;
  background-color: #fff;
}

.dark-mode input[type="checkbox"].terms-checkbox {
  background-color: #000;
}
.dark-mode input[type="checkbox"].terms-checkbox-grey {
  border: 2px solid rgba(255, 255, 255, 0.7);
}

.dark-mode input[type="checkbox"].terms-checkbox:hover:not(:checked) {
  border-color: #fff;
}
