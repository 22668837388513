@import "../../styles/variables/colors";
@import "../../styles/mixins/flex";
@import "../../styles/mixins/dimensions";
.dashbord-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dashbord-btn {
  margin-top: 30px;
  color: $color-black;
}

.feature-section {
  @include justify-content-between;
}

.dashbord-img {
  @include justify-content-between;
}

.dashbord-img .first-img img {
  @include w-100;
}

.dashbord-img .secend-img img {
  margin-top: 100px;
  width: 100%;
}
.end-secend {
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .dashbord-img .secend-img img {
    margin-top: 30px;
  }
}

.blog-post-rerender hr {
  height: 1px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.blog-post-rerender h1 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.blog-post-rerender h1 strong {
  background-color: #173e68;
  color: #fff;
  padding: 10px;
}
.blog-post-rerender h2 {
  text-align: center;
}

.blog-post-rerender h3 {
  font-weight: bold;
  font-size: 30px;
}
.blog-post-rerender p,
.blog-post-rerender li {
  font-weight: 100;
  font-size: 24px;
}
@media screen and (max-width: 600px) {
  .blog-post-rerender p,
  .blog-post-rerender li {
    font-size: 16px;
  }
  .blog-post-rerender h3 {
    font-size: 24px;
  }
}
.blog-post-rerender ul,
.blog-post-rerender p,
.blog-post-rerender h2,
.blog-post-rerender h3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.blog-post-rerender p strong {
  color: #000;
}
.blog-post-rerender li::marker {
  color: #000;
}
.blog-post-rerender li strong {
  color: #000;
}
.dark-mode .blog-post-rerender p strong {
  color: #fff;
}
.dark-mode .blog-post-rerender li::marker {
  color: #fff;
}
.dark-mode .blog-post-rerender li strong {
  color: #fff;
}

.blog-post-rerender h2 strong,
.blog-post-rerender h3 strong {
  display: block;
  text-align: left;
  margin-bottom: 25px;
}

.blog-post-rerender div a {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: #fff;
  text-align: center;
  border: 1px solid #173e68;
  background-color: #173e68;
  padding: 15px;
  border-radius: 10px;
}

.blog-post-rerender figure {
  display: block;
  text-align: center;
}
.blog-post-rerender figure a {
  display: block;
  text-align: center;
  width: auto;
}
.blog-post-rerender img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
.blog-post-rerender figure a {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.card {
  display: flex;
  align-items: center;
  width: calc(33.33% - 10px);
  padding-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 40%;
  height: 100%;
  margin-right: 10px;
}

.title {
  font-weight: bold;
  text-align: left;
}
