.wrapper {
  margin-right: 150px;
}
.overview-wrapper{
  margin: 300px 0;
}
.overview-text.overview-content.subscribe-page,.overview-text.overview-content.philosophypage,.overview-text.overview-content.categorypage{
  @media only screen and (max-width:1200px) {
    width: 85%;
   }
   @media only screen and (max-width:600px) {
   margin-left: 0;
   width: 100%;
   }
  >p{
    width: 900px;
    max-width: 100%;
    line-height: 32px;
    @media only screen and (max-width:1200px) {
      font-size: 24px !important;
      line-height: 30px !important;
     }
     @media only screen and (max-width:992px) {
      max-width: 80%;
     }
     @media only screen and (max-width:600px) {
      max-width: 100%;
      font-size: 20px !important;
      line-height: 23px !important;
     }

    &:nth-child(2){
      position: relative;
      margin: 25px 0;
      &::after{
        content: "";
        position: absolute;
        bottom: 45px;
        left: 0;
        width: 290px;
        height: 1px;
        background-color: #000;
        display: none;
      }
      >span{
        &:nth-child(16){
          font-weight: bold;
          position: relative;
         
        }
        &:nth-child(17){
          font-weight: bold;
         
        }
        &:nth-child(18){
          font-weight: bold;
        
        }
        &:nth-child(28){
          font-weight: bold;
        
        }
        &:nth-child(29){
          font-weight: bold;
        
        }

      }
    }
  }


}
.overview-text.overview-content.philosophypage,.overview-text.overview-content.categorypage{
  >p{
    >span{
      &:nth-child(16){
        font-weight: inherit !important;
        position: relative;
       
      }
      &:nth-child(17){
        font-weight: inherit !important;
       
      }
      &:nth-child(18){
        font-weight: inherit !important;
      
      }
      &:nth-child(28){
        font-weight: inherit !important;
      
      }
      &:nth-child(29){
        font-weight: inherit !important;
      
      }

    }
  }
}
.subscribe-date{
  display: flex;
  flex-direction: column;
  line-height: 80px;
  margin-bottom: 350px;
  @media only screen and (max-width: 992px){
    line-height: 60px;
  }
  @media only screen and (max-width: 600px){
    line-height: 40px;
  }

}
.what-happens{
  width: 990px;
  max-width: 100%;
  line-height: 85px;
  margin-bottom: 100px !important;
  @media only screen and (max-width:992px) {
    
    line-height: 60px !important;
    width: 750px;
   }
   @media only screen and (max-width:600px) {
    
    line-height: 40px !important;
    margin-bottom: 30px !important;
   }
  ~p{
    width: 900px;
    line-height: 33px;
    max-width: 100%;
    margin-bottom: 200px !important;
    @media only screen and (max-width:992px) {
    
    max-width: 85%;
     }
     @media only screen and (max-width:600px) {
    
      max-width: 80%;
       }
    
  }
}
.what-to-do{
  width: 500px;
  max-width: 100%;
  line-height: 90px;
  margin-bottom: 100px !important;
  margin-top: 500px;
  @media only screen and (max-width:992px) {
    
    width: 450px;
    max-width: 100%;
    line-height: 60px;
    margin-bottom: 100px !important;
     }
     @media only screen and (max-width:600px) {
    
      width: 250px;
      max-width: 100%;
      line-height: 40px;
      margin-bottom: 30px !important;
      margin-top: 200px;
       }
    
  ~p{
    width: 900px;
    line-height: 33px;
    max-width: 100%;
    margin-bottom: 200px !important;
    @media only screen and (max-width:992px) {
    
      max-width: 85%;
       }
       @media only screen and (max-width:600px) {
      
        max-width: 80%;
         }
  }
}
.overview-title.subscribepage{
  /*
  @media only screen and (max-width:600px) {
    
    width: 450px;
    max-width: 100%;
    line-height: 60px;
    margin-bottom: 100px !important;
     }
     */
}
.reopentxt{
  @media only screen and (max-width:600px) {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
     }
}