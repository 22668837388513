.ticker-view {
  --clr-number: white;
  --clr-number-to-increase: rgb(47, 164, 47);
  --clr-number-to-decrease: rgb(228, 64, 64);

  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  overflow-y: hidden;
  position: relative;
  color: var(--clr-number);
}
.dark-mode .ticker-view {
  --clr-number: white;
  --clr-number-from: white;
}
.light-mode .ticker-view {
  --clr-number: black;
  --clr-number-from: black;
}

.ticker-view.price-small {
  overflow-y: hidden;
  color: var(--clr-number);
}

.ticker-view span {
  margin: 0;
  color: var(--clr-number);
}

.number-placeholder {
  visibility: hidden;
}

.ticker-column-container {
  position: relative;
}

.ticker-column {
  position: absolute;
  width: 100%;
  height: 1000%;
  bottom: 0;
}

.ticker-digit {
  height: 10%;
}
.ticker-digit span {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ticker-column.increase {
  animation: pulseGreen 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}
.ticker-column.decrease {
  animation: pulseRed 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

@keyframes pulseGreen {
  0%,
  100% {
    --clr-number: var(--clr-number-from);
  }

  50% {
    --clr-number: var(--clr-number-to-increase);
  }
}

@keyframes pulseRed {
  0%,
  100% {
    --clr-number: var(--clr-number-from);
  }

  50% {
    --clr-number: var(--clr-number-to-decrease);
  }
}
