.button {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: x-large;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 10px 8px;
  cursor: pointer;
  border-radius: 32px;
}

.button-white {
  background-color: #fff;
  color: black;
}

.button-success {
  background-color: #000;
  color: white;
}

.button-error {
  background-color: red;
  color: white;
}

.referral-code-input {
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 36px;
  font-weight: bold;
  box-shadow: 0 0 0 0 transparent;
}

.referral-code-input input {
  border: none;
  outline: none;
}

.referral-code-input input::placeholder {
  font-weight: bold;
}
