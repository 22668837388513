.blurry-overlay {
  content: "";
  position: absolute;
  top: 32px;
  left: 64px;
  width: calc(100% - 64px);
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  pointer-events: none;
  border-radius: 16px;
}

.dark-mode .blurry-overlay {
  background: rgba(121, 121, 121, 0.2);
}

.blog-blurry-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  pointer-events: none;
  border-radius: 16px;
}

.dark-mode .blog-blurry-overlay {
  background: rgba(121, 121, 121, 0.5);
}
