.header-text {
  font-size: var(--Heading-95);
  margin: 230px 0 334px 203px;
  text-align: left;
}
.hero-img-container {
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
}
.hero-img {
  width: 100%;
}
.overview-section {
  /* margin: 203px; */
}
.overview-section p:first-of-type {
  font-size: 35px;
  font-weight: bold;
  margin-right: 30px;
}
.overview-section p {
  font-size: 30px;
}

@media only screen and (max-width: 900px) {
  .header-text {
    /* font-size: 80px; */
    /* margin: 200px 150px; */
  }
}

@media only screen and (max-width: 600px) {
  .overview-section {
    flex-direction: column;
  }
  .header-text {
    /* font-size: 50px; */
    /* margin: 100px 50px; */
  }

  .overview-section {
    /* margin: 50px; */
  }
  .overview-section h6 {
    font-size: 20px;
  }
  .overview-section p {
    font-size: 20px;
    margin-top: 20px;
  }
}
