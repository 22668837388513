.input-wrapper {
  margin-bottom: 60px;
  flex-direction: column;
  height: 70px;
}
.d-flex {
  display: flex;
}
.justify-content-start {
  justify-content: start;
}
.align-items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.input-form-mobile {
  width: 80%;
  height: auto !important;
}

.input-form-referal {
  width: 65%;
}

.verify-btn {
  margin: 0 !important;
}

.fs-20 {
  font-size: var(--Font-20);
}

.pay-btn {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tax-text {
  margin-top: 15px;
}

@media only screen and (max-width: 600px) {
  .amount-wrapper {
    flex-direction: column;
    align-items: start;
  }
  .tax-text {
    margin: 20px 0;
  }
  .fs-20 {
    font-size: 16px;
  }
  .fs-55 {
    font-size: 35px !important;
  }
}

.accordion-card {
  width: 100%;
  margin: 0;
}
.accordion-card h6 {
  font-size: var(--Font-30);
  margin: 40px 0 10px 0;
  font-family: AvertaSemi;
  font-weight: 700;
}
.accordion-card p {
  font-size: 25px;
}

@media only screen and (max-width: 900px) {
  .accordion-card h6 {
    font-size: 20px;
    /* margin: 40px 0 15px 0; */
    font-weight: bold;
  }
  .accordion-card p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .f-direction {
    flex-direction: column;
  }
  .w-35 {
    width: 100%;
  }
}
